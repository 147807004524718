import style from "./XLTitle.module.scss";
import StyleHelper from "../../../services/Style.helper";

interface TitleInterface {
    text: string[],
    sub: string[],
    since?: string | null,
    classes?: string | null,
}

const XLTitle = (props: TitleInterface) => {
    return (
      <h1 className={StyleHelper.concatClass([
          style.container,
          props.classes ?? ''
      ])}>
          {
              props.text.map((text: string, index: number) => <span className={style.text} key={index}>{text}</span>)
          }
          {
              props.sub.map((text: string, index: number) => <span className={style.subText} key={index}>{text}</span>)
          }
          {
              props.since ? <span className={style.since}>{props.since}</span> : null
          }
      </h1>
    );
}

export default XLTitle;
