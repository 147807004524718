import style from "./ProjectCard.module.scss";
import React from "react";
import Tag from "../Tag/Tag";
import {Link} from "react-router-dom";
import RoutesEnum from "../../enums/Routes.enum";
import Image from "../../components/Image/Image";

interface ProjectCardInterface {
    image: string;
    tags: string[];
    title: string;
    link: RoutesEnum;
}

const ProjectCard = (props: ProjectCardInterface) => {

    const randomInt = Math.floor(Math.random() * (100 - 1 + 1) + 1);

    const onMouseOut = (e: any) => {
        const currentCard = getCurrentCard();

        if (!currentCard) {
            return;
        }

        currentCard.style.transform = 'perspective(500px) scale(1) rotateX(0) rotateY(0)'
    };

    const onMouseDown = (e: any) => {
        const currentCard = getCurrentCard();

        if (!currentCard) {
            return;
        }

        currentCard.style.transform = 'perspective(500px) scale(1) rotateX(0) rotateY(0)'
    };

    const onMouseUp = (e: any) => {
        const currentCard = getCurrentCard();

        if (!currentCard) {
            return;
        }

        currentCard.style.transform = 'perspective(500px) scale(1) rotateX(0) rotateY(0)'
    };

    const onMouseMove = (e: any) => {
        const currentCard = getCurrentCard();

        if (!currentCard) {
            return;
        }

        const width = currentCard.offsetWidth;
        const height = currentCard.offsetHeight;

        /* Store the x position */
        const xVal = e.nativeEvent.layerX;
        /* Store the y position */
        const yVal = e.nativeEvent.layerY;

        /*
          * Calculate rotation valuee along the Y-axis
          * Here the multiplier 20 is to
          * Control the rotation
          * You can change the value and see the results
          */
        const yRotation = 5 * ((xVal - width / 2) / width)

        /* Calculate the rotation along the X-axis */
        const xRotation = -5 * ((yVal - height / 2) / height)

        /* Generate string for CSS transform property and apply the calculated transformation */
        currentCard.style.transform = 'perspective(500px) scale(1) rotateX(' + xRotation + 'deg) rotateY(' + yRotation + 'deg)'
    }

    const getCurrentCard = (): HTMLElement | null => {
        const currentCard = document.getElementById(randomInt.toString());

        if (!currentCard) {
            return null;
        }

        return currentCard;
    }

    return (
        <Link to={props.link}
              className={style.card}
              id={randomInt.toString()}
              onMouseDown={onMouseDown}
              onMouseOut={onMouseOut}
              onMouseUp={onMouseUp}
              onMouseMove={onMouseMove}
        >
            <Image src={props.image} alt={props.title} classes={[style.img]}/>
            {
                props.tags.map((tag: string, index: number) => <Tag key={index} title={tag}/>)
            }
            <p className={style.title}>{props.title}</p>
        </Link>
    )
}

export default ProjectCard;
