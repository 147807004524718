import React from "react";
import style from "./About.module.scss";
import julia from '../../assets/home/49ed29a171d0b0b551cec7aeb9a9147e.jpg';
import plantes from '../../assets/about/plantes.svg';
import pic from '../../assets/about/pic.svg';
import bea from '../../assets/about/bea.svg';
import flower from '../../assets/about/flower.svg';
import cocktail from '../../assets/about/cocktail.svg';
import List from "../../components/List/List";
import RoundButton from "../../components/Round/Button/RoundButton";
import StyleHelper from "../../services/Style.helper";
import RoundLink from "../../components/Round/Link/RoundLink";
import XLTitle from "../../components/Titles/XLTitle/XLTitle";
import Text from "../../components/Text/Text";
import {useTranslation} from "react-i18next";
import Image from "../../components/Image/Image";

const About = () => {

    const {t} = useTranslation();

    const downloadCV = () => {
    }

    return (
        <div>
            <div className={StyleHelper.concatClass([
                style.grid,
                style.grid__12,
                style.grid__spacing__1
            ])}>
                <div className={StyleHelper.concatClass([
                    style.col_xl_1_6,
                    style.col_lg_1_6,
                    style.col_md_1_13,
                    style.col_sm_1_13,
                    style.container
                ])}>
                    <XLTitle text={[t('about.title')]} sub={[t('about.subtitle')]}/>
                    <Text classes={style.container__text}
                          text={t('about.texts.1')}
                    />
                    <Text classes={style.container__text}
                          text={t('about.texts.2')}
                    />
                    <Text classes={style.container__text}
                          text={t('about.texts.3')}
                    />
                </div>

                <Image
                    classes={[
                        style.col_xl_7_11,
                        style.col_lg_7_11,
                        style.col_md_3_9,
                        style.col_sm_3_9,
                        style.img,
                    ]}
                    src={julia}
                    alt={t('fullName')}/>

                <div className={StyleHelper.concatClass([
                    style.col_xl_11_13,
                    style.col_lg_11_13,
                    style.col_md_9_13,
                    style.col_sm_10_13,
                    style.container
                ])}>
                    <RoundButton text={'Mon CV'} color={'white'} onClick={downloadCV} classes={[style.btnWrapper]}/>
                </div>

                <div className={StyleHelper.concatClass([
                    style.col_xl_8_13,
                    style.col_lg_8_13,
                    style.col_md_1_13,
                    style.col_sm_1_13,
                    style.skills
                ])}>
                    <List title={'Expertises'}
                          subtitle={'En perpétuelles évolutions'}
                          items={[
                              'Direction artistique',
                              'Branding & Identité',
                              'Design digital',
                              'UI/UX design',
                              'Motion design',
                              'Illustrations'
                          ]}/>
                </div>

                <div className={StyleHelper.concatClass([
                    style.col_xl_1_6,
                    style.col_lg_1_6,
                    style.col_md_1_13,
                    style.col_sm_1_13,
                    style.tools
                ])}>
                    <List title={'Des outils'}
                          subtitle={'Bien aiguisés'}
                          items={[
                              'Figma / Adobe Xd',
                              'Adobe - Illustrator / photoshop / Indesign',
                              'Premier pro / After effect',
                              'Notion',
                              '?? Other',
                          ]}/>
                </div>

                <Image classes={[
                    style.col_xl_1_4,
                    style.col_lg_1_4,
                    style.col_md_1_4,
                    style.col_sm_1_4,
                    style.ellipse__pic,
                    style.ellipse
                ]} src={plantes} alt={t('Plantes')}/>

                <RoundLink text={'Voir sur instagram'} color={'black'} link={'/'} classes={[
                    style.col_xl_4_6,
                    style.col_lg_4_6,
                    style.col_md_4_6,
                    style.col_sm_4_6,
                    style.ellipse__link,
                ]}/>

                <Image classes={[
                    style.col_xl_7_8,
                    style.col_lg_7_8,
                    style.col_md_7_8,
                    style.col_sm_7_8,
                    style.ellipse__pic,
                    style.ellipse__pic__translateDown,
                    style.ellipse
                ]} src={pic} alt={t('Pic')}/>

                <Image classes={[
                    style.col_xl_9_11,
                    style.col_lg_9_11,
                    style.col_md_9_11,
                    style.col_sm_9_11,
                    style.ellipse__pic,
                    style.ellipse__pic__translateDown,
                    style.ellipse
                ]} src={cocktail} alt={t('Cocktail')}/>

                <h3 className={StyleHelper.concatClass([
                    style.col_xl_6_9,
                    style.col_lg_6_9,
                    style.col_md_6_9,
                    style.col_sm_6_9,
                    style.ellipse__title
                ])}>illu.lia
                </h3>
                <h4 className={StyleHelper.concatClass([
                    style.col_xl_6_9,
                    style.col_lg_6_9,
                    style.col_md_6_9,
                    style.col_sm_6_9,
                    style.ellipse__subtitle
                ])}>ça aussi c’est moi
                </h4>
                <p className={StyleHelper.concatClass([
                    style.col_xl_5_10,
                    style.col_lg_5_10,
                    style.col_md_5_10,
                    style.col_sm_5_10,
                    style.ellipse__text
                ])}>Voici un petit aperçu de mon instagram dédié à mes illustrations personnelles réalisées sur
                    Procreate
                </p>

                <Image classes={[
                    style.col_xl_3_5,
                    style.col_lg_3_5,
                    style.col_md_3_5,
                    style.col_sm_3_5,
                    style.ellipse,
                    style.ellipse__pic,
                    style.ellipse__pic__flower
                ]} src={flower} alt={t('Flower')}/>

                <Image classes={[
                    style.col_xl_10_13,
                    style.col_lg_10_13,
                    style.col_md_10_13,
                    style.col_sm_10_13,
                    style.ellipse,
                    style.ellipse__pic,
                    style.ellipse__pic__bee
                ]} src={bea} alt={t('Bee')}/>

            </div>
            {/* End Grid */}
            <div className={style.blackBar}>
                <h3 className={style.blackBar__title}>Et sur le plan perso</h3>
                <p className={style.blackBar__text}>Dessin . Ski . Drone . Vidéo . Montagne . Jeux de société &
                    Raclette...</p>
            </div>
        </div>
    )
}

export default About;
