import React from "react";
import style from "./Five.module.scss";
import StyleHelper from "../../services/Style.helper";
import {useTranslation} from "react-i18next";
import XLTitle from "../../components/Titles/XLTitle/XLTitle";

interface QualityInterface {
    curious: string,
    honest: string,
    flexible: string,
    empathetic: string,
    coop: string
}

const Five = () => {

    const {t} = useTranslation();
    const qualities = {
        curious: t('five.quality.curious'),
        honest: t('five.quality.honest'),
        flexible: t('five.quality.flexible'),
        empathetic: t('five.quality.empathetic'),
        coop: t('five.quality.coop')
    }

    const getQualities = (): QualityInterface[] => {
        const preparedQualities = [];
        for (let i = 0; i < 5; i++) {
            preparedQualities.push(qualities);
        }

        return preparedQualities;
    }

    return (
        <div>
            <div className={StyleHelper.concatClass([
                style.grid,
                style.grid__12,
                style.grid__spacing__1
            ])}>
                <XLTitle classes={StyleHelper.concatClass([
                    style.col_xl_1_9,
                    style.col_lg_1_9,
                    style.col_md_1_13,
                    style.col_sm_1_13,
                ])}
                         text={[t("five.title")]}
                         sub={[t("five.subtitle")]}
                         since={t("five.since")}/>
            </div>

            <ul className={style.slider}>
                {
                    getQualities().map((quality) => {
                        return (
                            <li className={style.slider__container}>
                                <span className={style.slider__container__element}>{quality.curious}</span>
                                <span className={style.slider__container__element}>{quality.honest}</span>
                                <span className={style.slider__container__element}>{quality.flexible}</span>
                                <span className={style.slider__container__element}>{quality.empathetic}</span>
                                <span className={style.slider__container__element}>{quality.coop}</span>
                            </li>
                        );
                    })
                }
            </ul>
        </div>
    )
}

export default Five;
