import style from "./List.module.scss";
import React from "react";

interface ListInterface {
    title: string;
    subtitle: string;
    items: string[];
}

const List = (props: ListInterface) => {

    return (
        <div className={style.expertise}>
            <p className={style.expertise__title}>{props.title}</p>
            <p className={style.expertise__subtitle}>{props.subtitle}</p>
            <ul className={style.expertise__list}>
                {
                    props.items.map((item: string, index: number) => {
                        return <li key={index} className={style.expertise__list__item}>{item}</li>
                    })
                }
            </ul>
        </div>
    )
}

export default List;
