import style from "./MTitle.module.scss";
import StyleHelper from "../../../services/Style.helper";

interface TitleInterface {
    text: string[],
    classes?: string | null,
    since?: string | null,
}

const MTitle = (props: TitleInterface) => {
    return (
      <h2 className={StyleHelper.concatClass([
          style.container,
          props.classes ?? ''
      ])}>
          {
              props.text.map((text: string, index: number) => <span className={style.text} key={index}>{text}</span>)
          }
          {
              props.since ? <span className={style.since}>{props.since}</span> : null
          }
      </h2>
    );
}

export default MTitle;
