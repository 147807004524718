import React from "react";
import style from "./Projects.module.scss";
import epicurien from "../../assets/alaune/e-picurien.jpg";
import vrai from "../../assets/alaune/vrais-savent.jpg";
import boutdubou from "../../assets/alaune/le-bout-du-bout.jpg";
import labymind from "../../assets/alaune/labymind.jpg";
import youps from "../../assets/alaune/youpss.jpg";
import cinqTerres from "../../assets/alaune/5terres.jpg";
import basilique from "../../assets/51a622493e837bee339b95d771753cd3.jpg";
import iserba from "../../assets/00e9c998b39e72b5d1df49c73c70e77a.jpg";
import gesec from "../../assets/f599e5a4c327aa825f7dad68d62bf1bf.jpg";
import ast from "../../assets/18c2dcb6bccbb1870a8d7cf56759d399.jpg";
import jda from "../../assets/53f4f4141212819fb59d704769e068bc.jpg";
import ProjectCard from "../../components/ProjectCard/ProjectCard";
import StyleHelper from "../../services/Style.helper";
import RoutesEnum from "../../enums/Routes.enum";
import Tag from "../../components/Tag/Tag";
import XLTitle from "../../components/Titles/XLTitle/XLTitle";
import Image from "../../components/Image/Image";
import {useTranslation} from "react-i18next";


const Projects = () => {

    const {t} = useTranslation();

    return (
        <div>
            <div className={StyleHelper.concatClass([
                style.grid,
                style.grid__12,
                style.grid__spacing__1
            ])}>
                <div className={StyleHelper.concatClass([
                    style.col_xl_1_13,
                    style.col_lg_1_13,
                    style.col_md_1_13,
                    style.col_sm_1_13,
                ])}>
                    <XLTitle text={[t('projects.title')]} sub={[t('projects.sub')]} />
                </div>
            </div>
            <div className={style.menu}>
                <div className={StyleHelper.concatClass([
                    style.grid,
                    style.grid__12,
                    style.grid__spacing__1
                ])}>
                    <div className={StyleHelper.concatClass([
                        style.col_xl_1_6,
                        style.col_lg_1_6,
                        style.col_md_1_13,
                        style.col_sm_1_13,
                        style.menu__tags
                    ])}>
                        <Tag title={t('tags.ui')} padding={'padding__40'}/>
                        <Tag title={t('tags.branding')} padding={'padding__40'}/>
                        <Tag title={t('tags.direction')} padding={'padding__40'}/>
                    </div>

                    <div className={StyleHelper.concatClass([
                        style.col_xl_8_13,
                        style.col_lg_8_13,
                        style.col_md_1_13,
                        style.col_sm_1_13,
                    ])}>
                        <p className={style.menu__subTitle}>
                            {t('projects.discover')}
                        </p>
                    </div>
                </div>
            </div>

            <div className={StyleHelper.concatClass([
                style.grid,
                style.grid__12,
                style.grid__spacing__1
            ])}>
                <div className={StyleHelper.concatClass([
                    style.col_xl_2_7,
                    style.col_lg_2_7,
                    style.col_md_1_9,
                    style.col_sm_1_13,
                    style.projects__item,
                    style.projects__epi
                ])}>
                    <ProjectCard image={epicurien}
                                 tags={[t('tags.branding'), t('tags.website')]}
                                 title={t('projects.epicurien.title')}
                                 link={RoutesEnum.Epicurien}/>
                </div>
                <div className={StyleHelper.concatClass([
                    style.col_xl_10_13,
                    style.col_lg_10_13,
                    style.col_md_5_13,
                    style.col_sm_1_13,
                    style.projects__item,
                ])}>
                    <ProjectCard image={vrai}
                                 tags={[t('tags.website')]}
                                 title={t('projects.vrais.title')}
                                 link={RoutesEnum.Vrais}/>
                </div>
                <div className={StyleHelper.concatClass([
                    style.col_xl_8_11,
                    style.col_lg_8_11,
                    style.col_md_1_9,
                    style.col_sm_1_13,
                    style.projects__item,
                    style.projects__bou
                ])}>
                    <ProjectCard image={boutdubou}
                                 tags={[t('tags.branding'), t('tags.website')]}
                                 title={t('projects.boutdubout.title')}
                                 link={RoutesEnum.BoutDuBout}/>
                </div>
                <div className={StyleHelper.concatClass([
                    style.col_xl_1_4,
                    style.col_lg_1_4,
                    style.col_md_5_13,
                    style.col_sm_1_13,
                    style.projects__item,
                    style.projects__laby
                ])}>
                    <ProjectCard image={labymind}
                                 tags={[t('tags.branding')]}
                                 title={t('projects.labymind.title')}
                                 link={RoutesEnum.Labymind}/>
                </div>
                <div className={StyleHelper.concatClass([
                    style.col_xl_7_12,
                    style.col_lg_7_12,
                    style.col_md_1_9,
                    style.col_sm_1_13,
                    style.projects__item,
                    style.projects__youps
                ])}>
                    <ProjectCard image={youps}
                                 tags={[t('tags.branding'), t('tags.website')]}
                                 title={t('projects.youpss.title')}
                                 link={RoutesEnum.Youpss}/>
                </div>
                <div className={StyleHelper.concatClass([
                    style.col_xl_3_6,
                    style.col_lg_3_6,
                    style.col_md_5_13,
                    style.col_sm_1_13,
                    style.projects__item,
                    style.projects__cinq
                ])}>
                    <ProjectCard image={cinqTerres}
                                 tags={[t('tags.website')]}
                                 title={t('projects.terres.title')}
                                 link={RoutesEnum.Terres}/>
                </div>
            </div>

            <div className={style.round}>
                <div className={StyleHelper.concatClass([
                    style.grid,
                    style.grid__12,
                    style.grid__spacing__1
                ])}>
                    <Image
                        classes={[
                            style.col_xl_1_3,
                            style.col_lg_1_3,
                            style.col_md_1_6,
                            style.col_sm_1_6,
                            style.round__img,
                            style.round__img__one
                        ]}
                        src={ast}
                        alt={'AST'}/>
                    <Image
                        classes={[
                            style.col_xl_6_8,
                            style.col_lg_6_8,
                            style.col_md_7_13,
                            style.col_sm_7_13,
                            style.round__img,
                            style.round__img__two
                        ]}
                        src={basilique}
                        alt={'Basilique sacré coeur'}/>
                    <Image
                        classes={[
                            style.col_xl_11_13,
                            style.col_lg_11_13,
                            style.col_md_4_10,
                            style.col_sm_4_10,
                            style.round__img,
                            style.round__img__three
                        ]}
                        src={jda}
                        alt={'JDA'}/>
                </div>

                <div className={StyleHelper.concatClass([
                    style.grid,
                    style.grid__12,
                    style.grid__spacing__1
                ])}>
                    <div className={StyleHelper.concatClass([
                        style.col_xl_5_9,
                        style.col_lg_5_9,
                        style.col_md_5_9,
                        style.col_sm_5_9,
                    ])}>
                        <div className={style.round__box}>
                            <h3 className={style.round__box__title}>Autres projets à découvrir</h3>
                            <span className={style.round__box__action}>Rencontrons-nous !</span>
                        </div>
                    </div>
                </div>

                <div className={StyleHelper.concatClass([
                    style.grid,
                    style.grid__12,
                    style.grid__spacing__1
                ])}>
                    <Image
                        classes={[
                            style.col_xl_3_5,
                            style.col_lg_3_5,
                            style.col_md_2_7,
                            style.col_sm_2_7,
                            style.round__img,
                        ]}
                        src={iserba}
                        alt={'ISERBA'}/>

                    <Image
                        classes={[
                            style.col_xl_8_10,
                            style.col_lg_8_10,
                            style.col_md_8_13,
                            style.col_sm_8_13,
                            style.round__img,
                            style.round__img__last,
                        ]}
                        src={gesec}
                        alt={'GESEC'}/>
                </div>
            </div>
        </div>
    )
}

export default Projects;
