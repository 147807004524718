import React from "react";
import style from "./Tag.module.scss";
import StyleHelper from "../../services/Style.helper";

interface TagInterface {
    title: string,
    padding?: string
}

const Tag = (props: TagInterface) => {

    return (
        <span className={StyleHelper.concatClass([
            style.tag,
            props.padding ? style[props.padding] : '',
        ])}>{props.title}</span>
    )
}

export default Tag;
