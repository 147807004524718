import style from './Footer.module.scss';
import React from "react";
import julia from "../../assets/home/49ed29a171d0b0b551cec7aeb9a9147e.jpg";
import illustration from "../../assets/illustration-himself.svg";
import RoundLink from "../Round/Link/RoundLink";
import RoutesEnum from "../../enums/Routes.enum";
import StyleHelper from "../../services/Style.helper";
import {Link, useLocation} from "react-router-dom";
import Image from "../Image/Image";
import {useTranslation} from "react-i18next";

const Footer = () => {
    const location = useLocation();
    const {t} = useTranslation();

    if (location.pathname === '/contact') {
        return (<div></div>);
    }

    return (
        <div className={style.footer}>
            <div className={StyleHelper.concatClass([
                style.grid,
                style.grid__12,
                style.grid__spacing__1
            ])}>
                <div className={StyleHelper.concatClass([
                    style.col_xl_1_6,
                    style.col_lg_1_6,
                    style.col_md_1_6,
                    style.col_sm_1_13,
                    style.footer__item
                ])}>
                    <span className={style.footer__title}>En savoir plus ?</span>
                </div>
                <div className={StyleHelper.concatClass([
                    style.col_xl_11_13,
                    style.col_lg_11_13,
                    style.col_md_8_13,
                    style.col_sm_1_13,
                    style.footer__author,
                ])}>
                    <Link to={'https://www.instagram.com/illu.lia/'} target={'_blank'}>
                        <span className={style.footer__author__title}>illu.lia</span>
                        <Image src={illustration} alt={t('fullName')} classes={[style.footer__author__pic]}/>
                    </Link>
                </div>
                <div className={StyleHelper.concatClass([
                    style.col_xl_1_5,
                    style.col_lg_1_5,
                    style.col_md_1_13,
                    style.col_sm_1_13,
                    style.footer__item
                ])}>
                    <Link to={RoutesEnum.Five} className={style.footer__five}>5 choses que tu ne sais pas encore sur moi</Link>
                </div>
                <div className={StyleHelper.concatClass([
                    style.col_xl_6_9,
                    style.col_lg_6_9,
                    style.col_md_1_13,
                    style.col_sm_1_13,
                    style.footer__item
                ])}>
                    <span className={style.footer__textToMe}>Ecrivons-nous & rencontrons-nous</span>
                    <span className={style.footer__email}>juliavacherj@gmail.com</span>
                </div>
            </div>

            <div className={StyleHelper.concatClass([
                style.grid,
                style.grid__12,
                style.grid__spacing__1
            ])}>
                <div className={StyleHelper.concatClass([
                    style.col_xl_2_4,
                    style.col_lg_2_4,
                    style.col_md_5_9,
                    style.col_sm_5_9,
                    style.footer__item,
                    style.footer__picmore
                ])}>
                    <Image src={julia} alt={t('fullName')} classes={[style.footer__img]}/>
                    <div className={style.footer__button}>
                        <RoundLink text={'Par ici'}
                                   color={'white'}
                                   fontSize={20}
                                   link={RoutesEnum.Contact}/>
                    </div>
                </div>

                <div className={StyleHelper.concatClass([
                    style.col_xl_6_13,
                    style.col_lg_6_13,
                    style.col_md_1_13,
                    style.col_sm_1_13,
                    style.footer__item
                ])}>
                    <span className={StyleHelper.concatClass([
                        style.footer__socialNetwork__title
                    ])}>Réseaux sociaux</span>
                    <div className={style.footer__socialNetwork__wrapper}>
                        <span className={StyleHelper.concatClass([
                            style.footer__socialNetwork__wrapper__item
                        ])}>
                            <Link to={'https://www.behance.net/juliavacher-crea?locale=fr_FR#'} target={'_blank'}>BEHANCE</Link>
                        </span>
                        <span className={StyleHelper.concatClass([
                            style.footer__socialNetwork__wrapper__item
                        ])}>
                            <Link to={'https://www.linkedin.com/in/julia-vacher-82517a123/'} target={'_blank'}>LINKEDIN</Link>
                        </span>
                        <span className={StyleHelper.concatClass([
                            style.footer__socialNetwork__wrapper__item
                        ])}>
                            <Link to={'https://www.instagram.com/julia.vacher.crea/'} target={'_blank'}>INSTAGRAM</Link>
                        </span>
                    </div>
                </div>

                <div className={StyleHelper.concatClass([
                    style.col_xl_6_13,
                    style.col_lg_6_13,
                    style.col_md_1_13,
                    style.col_sm_1_13,
                    style.footer__item,
                    style.footer__copyright
                ])}>
                    <p className={style.footer__copyright__text}>2024 © Vacher Julia - Tous droits réservés 
                        
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Footer;
