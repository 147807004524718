// @ts-nocheck
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationFR from "./translations/fr.json";

const resources = {
    fr: { translation: translationFR },
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: typeof navigator !== "undefined" ? navigator.language.split("-")[0] : "fr",
        fallbackLng: "fr",
        debug: false,
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
        react: {
            transKeepBasicHtmlNodesFor: ["br", "strong", "i"],
        },
    });

export default i18n;
