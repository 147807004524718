enum RoutesEnum {
    Home = "/",
    About = "/about",
    Projects = "/projects",
    Epicurien = "/projects/e-picurien",
    BoutDuBout = "/projects/bout-du-bout",
    Labymind = "/projects/labymind",
    Terres = "/projects/5-terres",
    Vrais = "/projects/vrais",
    Youpss = "/projects/youpss",
    Contact = "/contact",
    Five = "/five",
}

export default RoutesEnum;
