import style from "./WorkWithMe.module.scss";
import lele from "../../../assets/home/e4d5b5f85c574d1beae136285851437f.jpg";
import Axel from "../../../assets/home/axel.jpg";
import Julien from "../../../assets/home/julien.jpg";
import React, {useState} from "react";
import StyleHelper from "../../../services/Style.helper";
import Image from "../../../components/Image/Image";

export interface Commentary {
    firstName: string;
    lastName: string;
    job: string;
    comment: string;
    pic: string;
    columns: number[];
}

const WorkWithMe = () => {

    const commentaries: Commentary[] = [
        {
            firstName: 'Axel',
            lastName: 'Roche',
            job: 'Développeur',
            comment: 'Travailler avec Julia est un vrai plaisir ! Elle a une vision très précise des choses et son regard est toujours très cohérent et original. Pour couronner le tout, elle a une excellente connaissance des contraintes techniques liées au web et sait les transformer en forces et atouts.',
            pic: Axel,
            columns: [1, 5]
        },
        {
            firstName: 'Léa',
            lastName: 'Devissi',
            job: 'Cheffe de Projet',
            comment: 'C’est toujours un plaisir de collaborer avec Julia ! Elle sait être force de proposition autant en UX qu’en UI et sait parfaitement s’approprier un brief client. Elle ne se repose jamais sur ses acquis et sait faire preuve d’une grande créativité à chaque nouveau projet !',
            pic: lele,
            columns: [5, 9]
        },
        {
            firstName: 'Julien',
            lastName: 'Philippon',
            job: 'Developpeur Freelance',
            comment: `J'ai collaboré depuis quelques années avec Julia sur plusieurs projets : en agence et pour des projets personnels. C'est une artiste très à l'aise pour communiquer : elle saisit rapidement les goûts et les besoins des clients pour les orienter vers des créations sur mesure, que ce soit pour des identités graphiques ou des maquettes de sites internet. Elle sait prendre en compte dans ses maquettes les contraintes techniques liées à l'intégration et proposer des solutions pertinentes. De plus, la qualité de ses maquettes permet une intégration fluide et agréable, ce qui est un confort et un gain de temps appréciable pour les développeurs.`,
            pic: Julien,
            columns: [9, 13]
        }
    ];

    const [currentCommentary, setCurrentCommentary] = useState(commentaries[0]);

    const getActiveClass = (commentary: Commentary): string => {
        if (commentary.firstName === currentCommentary.firstName) {
            return style.work__item + ' ' + style.work__item__active;
        }

        return style.work__item;
    }

    const getColumnClass = (commentay: Commentary): string => {
        return style[`col_xl_${commentay.columns[0]}_${commentay.columns[1]}`];
    }

    return (
        <div className={style.wrapper}>
            <div className={StyleHelper.concatClass([
                style.grid,
                style.grid__12,
                style.grid__spacing__1
            ])}>
                <span className={StyleHelper.concatClass([
                    style.col_xl_1_13,
                    style.col_lg_1_13,
                    style.col_md_1_13,
                    style.col_sm_1_13,
                    style.work__title
                ])}>Ils ont travaillé avec moi...</span>
                <span className={StyleHelper.concatClass([
                    style.col_xl_1_5,
                    style.col_lg_1_5,
                    style.col_md_1_13,
                    style.col_sm_1_13,
                    style.work__subtitle
                ])}>...et en sont ressortis indemnes</span>
            </div>

            <div className={StyleHelper.concatClass([
                style.grid,
                style.grid__12
            ])}>
                {
                    commentaries.map((commentary: Commentary) => {
                        return (
                            <span key={commentary.firstName}
                                  onClick={() => setCurrentCommentary(commentary)}
                                  className={
                                      StyleHelper.concatClass([
                                          getColumnClass(commentary),
                                          getActiveClass(commentary)
                                      ])
                                  }>
                                {commentary.firstName}
                            </span>
                        )
                    })
                }
                <Image src={currentCommentary.pic} alt={currentCommentary.firstName} classes={[
                    style.col_xl_2_4,
                    style.col_lg_2_4,
                    style.col_md_1_13,
                    style.col_sm_1_13,
                    style.work__wrapper,
                    style.work__wrapper__pic
                ]}/>
                <div className={StyleHelper.concatClass([
                    style.col_xl_4_11,
                    style.col_lg_4_11,
                    style.col_md_1_13,
                    style.col_sm_1_13,
                    style.work__comment
                ])}>
                    <div className={style.work__comment__wrapper}>
                        <p className={style.work__comment__text}>{currentCommentary.comment}</p>
                        <span className={style.work__comment__author}>
                            {StyleHelper.concatClass([currentCommentary.firstName, currentCommentary.lastName])}
                        </span>
                        <span className={style.work__comment__job}>{currentCommentary.job}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WorkWithMe;
