import MTitle from "../Titles/MTitle/MTitle";
import StyleHelper from "../../services/Style.helper";
import style from "./TitleAndDescription.module.scss";
import Text from "../Text/Text";
import React from "react";

interface TitleAndDescriptionInterface {
    titles: string[],
    description: string,
}

const TitleAndDescription = (props: TitleAndDescriptionInterface) => {
    return (
        <div className={StyleHelper.concatClass([
            style.grid,
            style.grid__12,
            style.grid__spacing__1,
            style.container
        ])}>
            <MTitle text={props.titles}
                    classes={StyleHelper.concatClass([
                        style.col_xl_2_6,
                        style.col_lg_2_6,
                        style.col_md_1_13,
                        style.col_sm_1_13,
                        style.container__common,
                    ])}/>
            <Text
                text={props.description}
                classes={StyleHelper.concatClass([
                    style.col_xl_7_12,
                    style.col_lg_7_12,
                    style.col_md_1_13,
                    style.col_sm_1_13,
                    style.container__common,
                ])}/>
        </div>
    )
}

export default TitleAndDescription;
