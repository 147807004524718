import React from "react";
import style from "./Contact.module.scss";
import julia from '../../assets/home/49ed29a171d0b0b551cec7aeb9a9147e.jpg';
import StyleHelper from "../../services/Style.helper";
import RoundButton from "../../components/Round/Button/RoundButton";
import {Link} from "react-router-dom";
import XLTitle from "../../components/Titles/XLTitle/XLTitle";
import LTitle from "../../components/Titles/LTitle/LTitle";
import Text from "../../components/Text/Text";
import RoundLink from "../../components/Round/Link/RoundLink";
import Image from "../../components/Image/Image";
import {useTranslation} from "react-i18next";


const Contact = () => {

    const {t} = useTranslation();

    return (
        <div className={StyleHelper.concatClass([
            style.grid,
            style.grid__12,
            style.grid__spacing__1
        ])}>
            <div className={StyleHelper.concatClass([
                style.col_xl_1_9,
                style.col_lg_1_9,
                style.col_md_1_13,
                style.col_sm_1_13,
                style.wrapper
            ])}>
                <XLTitle text={['Une rencontre,']} sub={['Un café ?']}/>
            </div>

            <Image classes={[
                style.col_xl_2_5,
                style.col_lg_2_5,
                style.col_md_4_10,
                style.col_sm_4_10,
                style.padding,
                style.img
            ]} src={julia} alt={t('fullName')}/>

            <RoundButton text={'Télécharger mon CV'}
                         color={'white'}
                         classes={[
                             style.col_xl_5_7,
                             style.col_lg_5_7,
                             style.col_md_10_13,
                             style.col_sm_10_13,
                             style.padding,
                             style.rotate
                         ]}
                         onClick={() => console.log('downloadCV')}/>

            <div className={StyleHelper.concatClass([
                style.col_xl_7_13,
                style.col_lg_7_13,
                style.col_md_1_13,
                style.col_sm_1_13
            ])}>
                <LTitle text={['Vous n\'avez pas encore tout vu !']}
                        classes={StyleHelper.concatClass([
                            style.col_xl_1_6,
                            style.col_lg_1_6,
                            style.col_md_1_6,
                            style.col_sm_1_6,
                        ])}
                        since={'Je vous l’assure'}
                />
                <Text text={'Quoi de mieux qu’une rencontre pour vraiment connaître une personne. ' +
                    'Échangeons ensemble sur vos attentes et mon parcours.'}
                      classes={StyleHelper.concatClass([
                          style.col_xl_1_6,
                          style.col_lg_1_6,
                          style.col_md_1_6,
                          style.col_sm_1_6,
                          style.subPartText
                      ])}/>
                <div className={style.data}>
                    <span className={style.data__purple}>Ecrivons-nous & rencontrons-nous</span>
                    <span className={style.data__value}>juliavacherj@gmail.com</span>
                    <span className={style.data__purple}>Sinon, connectons-nous</span>
                    <div className={style.data__network}>
                        <span>Behance</span>
                        <span>Linkedin</span>
                        <span>Instagram</span>
                    </div>
                </div>
            </div>

            <Link className={StyleHelper.concatClass([
                style.col_xl_2_12,
                style.col_lg_2_12,
                style.col_md_1_13,
                style.col_sm_1_13,
                style.fiveWrapper
            ])} to={'/five'}>
                <div className={StyleHelper.concatClass([
                    style.col_xl_2_9,
                    style.col_lg_2_9,
                    style.col_md_2_10,
                    style.col_sm_2_10,
                    style.fiveWrapper__text
                ])}>
                    <span>5 choses que tu ne sais pas encore sur moi</span>
                </div>
                <div className={StyleHelper.concatClass([
                    style.col_xl_10_11,
                    style.col_lg_10_11,
                    style.col_md_11_12,
                    style.col_sm_11_12,
                    style.fiveWrapper__button
                ])}>
                    <RoundLink
                        text={'Par ici'}
                        color={'black'}
                        link={'/five'}
                        disableHover={true}/>
                </div>
            </Link>

        </div>
    )
}

export default Contact;
