import StyleHelper from "../../services/Style.helper";
import style from './Image.module.scss';
import {MutableRefObject, useEffect, useRef, useState} from "react";

interface ImageProps {
    src: string,
    alt: string,
    classes?: string[] | null,
    withEffect?: boolean,
}

const Image = ({src, alt, classes, withEffect = true}: ImageProps) => {

    const [isVisible, setIsVisible] = useState(false);
    const imageRef: MutableRefObject<any> = useRef(null);

    useEffect(() => {
        if (withEffect === false) {
            setIsVisible(true);
            return;
        }

        const currentImage = imageRef.current;
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        console.log('entry', entry);
                        setIsVisible(true);
                        observer.disconnect(); // Déconnecter l'observateur une fois l'image visible pour éviter des appels inutiles
                    }
                });
            },
            {
                threshold: [0, 0.25, 0.5, 0.75, 1]
            } // Déclenche quand 25% de l'image est visible
        );

        if (currentImage) {
            observer.observe(currentImage);
        }

        return () => {
            if (currentImage) {
                observer.unobserve(currentImage); // Cleanup pour éviter les fuites de mémoire
            }
        };
    }, [withEffect, imageRef]);

    return (
        <img ref={imageRef}
             src={src}
             alt={alt}
             className={StyleHelper.concatClass([
                 style.img,
                 withEffect ? style.img__withEffect : '',
                 isVisible ? style.img__visible : style.img__hidden,
                 ...classes ?? []
             ])}/>
    );
}

export default Image;
