import React from "react";
import style from "./Home.module.scss";
import julia from '../../assets/home/49ed29a171d0b0b551cec7aeb9a9147e.jpg';
import WorkWithMe from "./WorkWithMe/WorkWithMe";
import Projects from "./Projects/Projects";
import List from "../../components/List/List";
import RoundLink from "../../components/Round/Link/RoundLink";
import RoutesEnum from "../../enums/Routes.enum";
import StyleHelper from "../../services/Style.helper";
import XLTitle from "../../components/Titles/XLTitle/XLTitle";
import LTitle from "../../components/Titles/LTitle/LTitle";
import Text from "../../components/Text/Text";
import {useTranslation} from "react-i18next";
import Image from "../../components/Image/Image";


const Home = () => {

    const {t} = useTranslation();

    return (
        <div>
            <div className={StyleHelper.concatClass([
                style.grid,
                style.grid__12,
                style.grid__spacing__1
            ])}>
                <XLTitle classes={StyleHelper.concatClass([
                    style.col_xl_1_7,
                    style.col_lg_1_7,
                    style.col_md_1_13,
                    style.col_sm_1_13,
                    style.container,
                    style.mainText
                ])}
                         text={[t("home.titles.one"), t("home.titles.two")]}
                         sub={[t("home.subs.one")]}
                         since={t("home.since")}
                />
                <Image src={julia}
                       alt={t('fullName')}
                       classes={[
                           style.col_xl_8_11,
                           style.col_lg_8_11,
                           style.col_md_2_7,
                           style.col_sm_2_7,
                           style.container,
                           style.container__img
                       ]}/>
                <RoundLink text={t('home.buttons.discoverMe')}
                           color={'white'}
                           fontSize={20}
                           classes={[
                               style.col_xl_11_13,
                               style.col_lg_11_13,
                               style.col_md_7_11,
                               style.col_sm_7_11,
                               style.button
                           ]}
                           link={RoutesEnum.About}/>
            </div>

            <div className={StyleHelper.concatClass([
                style.grid,
                style.grid__12,
                style.grid__spacing__1,
                style.selection
            ])}>
                <LTitle
                    classes={StyleHelper.concatClass([
                        style.col_xl_8_13,
                        style.col_lg_8_13,
                        style.col_md_1_13,
                        style.col_sm_1_13,
                        style.selection__text
                    ])}
                    text={[t('home.titles.three')]}/>

                <Text text={t('home.texts.one')}
                      classes={StyleHelper.concatClass([
                          style.col_xl_1_6,
                          style.col_lg_1_6,
                          style.col_md_1_13,
                          style.col_sm_1_13
                      ])}
                />

                <div className={StyleHelper.concatClass([
                    style.col_xl_8_13,
                    style.col_lg_8_13,
                    style.col_md_1_13,
                    style.col_sm_1_13,
                    style.expertise,
                ])}>
                    <List title={t('home.lists.title')}
                          subtitle={t('home.lists.subtitle')}
                          items={[
                              t('home.lists.elements.1'),
                              t('home.lists.elements.2'),
                              t('home.lists.elements.3'),
                              t('home.lists.elements.4'),
                              t('home.lists.elements.5'),
                              t('home.lists.elements.6'),
                          ]}/>
                </div>
            </div>
            <Projects/>
            <WorkWithMe/>
        </div>
    )
}

export default Home;
