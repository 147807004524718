import style from "./Projects.module.scss";
import epicurien from "../../../assets/alaune/e-picurien.jpg";
import vrai from "../../../assets/alaune/vrais-savent.jpg";
import boutdubou from "../../../assets/alaune/le-bout-du-bout.jpg";
import React from "react";
import ProjectCard from "../../../components/ProjectCard/ProjectCard";
import RoutesEnum from "../../../enums/Routes.enum";
import RoundLink from "../../../components/Round/Link/RoundLink";
import StyleHelper from "../../../services/Style.helper";

const Projects = () => {

    return (
        <div className={style.projects}>
            <p className={style.projects__title}>Sélection de projets</p>
            <div className={StyleHelper.concatClass([
                style.grid,
                style.grid__12,
                style.grid__spacing__1
            ])}>
                <p className={StyleHelper.concatClass([
                    style.col_xl_3_5,
                    style.col_lg_3_5,
                    style.col_md_1_13,
                    style.col_sm_1_13,
                    style.projects__subtitle
                ])}>
                    Réalisés avec amour & jus de cerveau
                </p>
                <div className={StyleHelper.concatClass([
                    style.col_xl_1_6,
                    style.col_lg_1_6,
                    style.col_md_1_13,
                    style.col_sm_1_13,
                    style.projects__wrapper,
                    style.projects__epi
                ])}>
                    <ProjectCard image={epicurien}
                                 tags={['Branding', 'Site web']}
                                 title={'L’e-picurien'}
                                 link={RoutesEnum.Epicurien}/>
                </div>
                <RoundLink text={'Plus de projets par ici'}
                           color={'black'}
                           fontSize={20}
                           classes={[
                               style.col_xl_6_8,
                               style.col_lg_6_8,
                               style.button
                           ]}
                           link={RoutesEnum.Projects}/>
                <div className={StyleHelper.concatClass([
                    style.col_xl_10_13,
                    style.col_lg_10_13,
                    style.col_md_1_9,
                    style.projects__wrapper,
                    style.col_sm_1_13,
                ])}>
                    <ProjectCard image={vrai}
                                 tags={['Site web']}
                                 title={'Vrais savent'}
                                 link={RoutesEnum.Vrais}/>
                </div>
                <div className={StyleHelper.concatClass([
                    style.col_xl_7_10,
                    style.col_lg_7_10,
                    style.col_md_5_13,
                    style.col_sm_1_13,
                    style.projects__wrapper,
                    style.projects__bou
                ])}>
                    <ProjectCard image={boutdubou}
                                 tags={['Branding', 'Site web']}
                                 title={'Le bout du bout'}
                                 link={RoutesEnum.BoutDuBout}/>
                </div>
            </div>
        </div>
    )
}

export default Projects;
