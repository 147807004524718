import style from "./Text.module.scss";
import StyleHelper from "../../services/Style.helper";

interface TitleInterface {
    text: string,
    classes?: string | null,
}

const Text = (props: TitleInterface) => {
    return (
      <p className={StyleHelper.concatClass([
          style.container,
          props.classes ?? ''
      ])}>
          {
              props.text
          }
      </p>
    );
}

export default Text;
