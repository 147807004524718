import React from "react";
import style from "../Round.module.scss";
import StyleHelper from "../../../services/Style.helper";

interface RoundButtonInterface {
    text: string,
    color: string,
    fontSize?: number,
    onClick?: any,
    disableHover?: boolean,
    classes?: string[] | null,
}

const RoundButton = (props: RoundButtonInterface) => {

    const onClick = (event: any) => {
        event.preventDefault();
        props.onClick(event);
    }

    const getColor = (): string => {
        switch (props.color) {
            case 'white':
                return style.white;
            case 'black':
                return style.black;
            default:
                return style.white;
        }
    }

    const getFontSize = (): string => {
        switch (props.fontSize) {
            case 16:
                return style.fontSize__16;
            case 18:
                return style.fontSize__18;
            case 20:
                return style.fontSize__20;
            default:
                return style.fontSize__16;
        }
    }

    const isHoverEnable = (): string => {
        return props.disableHover ? '' : style.container__hover;
    }

    const getStyle = (): string => {
        return StyleHelper.concatClass([
            style.container,
            getColor(),
            getFontSize(),
            isHoverEnable()
        ]);
    }

    return (
        <div className={StyleHelper.concatClass(props.classes ?? [])}>
            <button onClick={onClick}
                    className={getStyle()}>
                <span className={style.container__text}>
                    {props.text}
                </span>
            </button>
        </div>
    )
}

export default RoundButton;
